.container {
  /* border: 5px solid brown; */
  padding: 50px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  box-sizing: border-box;
  background-color: rgb(185, 185, 185);
}

.addPartnerContainer {
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 500;
  border-radius: 25px;
  padding: 20px;
  background: linear-gradient(45deg, rgb(52, 77, 146), rgb(42, 124, 145));
  color: aliceblue;
}
.partner {
  display: flex;
  flex-direction: column;
}
.partner label {
  text-align: center;
}
.partner input {
  border: 1px solid grey;
  padding: 10px;
  font-size: 18px;
  border-radius: 15px;
  margin: 10px 0px 10px;
}

.partner input:focus {
  border: none;
  box-shadow: 4px 4px 2px rgb(207, 133, 21);
  outline: none;
  border-radius: 10px;
}

.partnerBtn input {
  border: none;
  font-size: 18px;
  font-weight: 600 !important;
  padding: 10px;
  margin-right: 25px;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  color: aliceblue;
}

#add {
  background-color: rgb(25, 122, 25);
}

#add:hover {
  background-color: rgb(28, 82, 28);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
