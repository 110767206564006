.container {
  /* border: 2px solid brown; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  box-sizing: border-box;
  /* background: linear-gradient(45deg, grey, black); */
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: aliceblue;
  flex-direction: column;
}

.errorDiv {
  font-size: 20px;
  background-color: red;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.imgContainer {
  width: 100%;
  height: 100vh;
  /* border: 2px solid green; */
  /* padding: 30px; */
  /* padding-left: 0px; */
  position: absolute;
  z-index: -1;
  top: 0%;
}
.imgContainer img {
  width: 100%;
  height: 100%;
  /* border-radius: 20px; */
}

.form {
  /* border: 2px solid green; */
  width: 500px;
  padding: 20px;
  /* background-color: rgb(245, 245, 245); */
  background: linear-gradient(45deg, #4a569d, #dc2424);
  border-radius: 15px;
  /* box-shadow: 0px 5px 2px rgb(4, 182, 182); */
  transition: 0.5s;
  cursor: pointer;
  color: aliceblue;
}

.formElement {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  position: relative;
}
.formElement input {
  font-size: 18px;
  padding: 5px;
  border: 0.5px solid grey;
  border-radius: 10px;
  position: relative;
}

.formElement input:focus {
  border: none;
  box-shadow: 4px 4px 2px skyblue;
  outline: none;
  border-radius: 10px;
}

.btn {
  display: flex;
  flex-direction: row-reverse;
}

.btn input {
  border: none;
  font-size: 18px;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(40, 98, 224);
  color: aliceblue;
  cursor: pointer;
}

.btn input:hover {
  background-color: rgb(5, 50, 146);
}

#login {
  text-align: center;
  font-size: 36px;
  /* color: rgb(35, 111, 197); */
  color: aliceblue;
}

.hide {
  position: absolute;
  left: 95%;
  z-index: 1000;
  color: black;
  top: 55%;
  transform: translate(-55%);
  color: grey;
}

@media screen and (max-width: 768px) {
  .imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .form {
    width: 80%;
    padding: 10px;
  }

  .hide {
    position: absolute;
    left: 90%;
    z-index: 1000;
    color: black;
    top: 55%;
    transform: translate(-55%);
    color: grey;
  }
}
