.container {
  background: linear-gradient(45deg, #024884, #c11d24);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  box-sizing: border-box;
  padding: 8px 15px 8px;
  width: 100%;
  /* border: 5px solid blue; */
}

.navbarSm {
  display: none;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarList {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  text-align: center;
  font-size: 20px;
  align-items: center;
}

.navbarItem {
  margin-right: 50px;
  cursor: pointer;
  /* border-bottom: 2px solid aliceblue; */
}

.navbarItem a {
  color: aliceblue;
  text-decoration: none;
}

.activeClass {
  color: skyblue !important;
  font-weight: 600;
  border-bottom: 0.5px solid skyblue;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.logo {
  width: 50px;
  height: 50px;
  background-color: aliceblue;
  border-radius: 8px;
  margin-right: 50px;
  cursor: pointer;
}

.logout a {
  color: aliceblue;
  text-decoration: none;
  font-size: 20px;
  margin-right: 50px;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbarSm {
    display: block;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: aliceblue;
  }

  .logo {
    width: 40px;
    height: 40px;
  }
  .threeBar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuContent {
    text-align: center;
    font-size: 18px;
  }
  .menuContent a {
    color: aliceblue;
    text-decoration: none;
  }
}
