.cardContainer {
  border: 2px solid blue;
  width: 300px;
  /* height: 350px; */
  margin: 15px;
  border-radius: 25px;
  box-sizing: border-box;
  padding: 20px;
}

.header {
  /* border-bottom: 0.5px solid black; */
  text-align: center;
  margin-bottom: 20px;
}
.header h2 {
  margin: 0;
}
.header p {
  margin: 10px;
  font-weight: 480;
  font-size: 17px;
}

.cardBody {
  display: grid;
  grid-template-columns: 90px 100px;
  grid-template-areas: "price1 data1" "price2 data2";
  padding: 10px 20px;
  text-align: left;
  grid-column-gap: 30px;
}

#price1 {
  grid-area: price1;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
#price2 {
  grid-area: price2;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

#data1 {
  grid-area: data1;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
#data2 {
  grid-area: data2;
  margin: 0;
}

.footer {
  text-align: center;
  margin-top: 37px;
}

.footer input {
  margin-bottom: 20px;
  width: 90%;
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
  outline: none;
}

.footer input:focus {
  box-shadow: 4px 4px 2px rgb(12, 52, 68);
  border: none;
}
.footer button {
  background-color: #0f3cc9;
  color: #fff;
  border-radius: 15px;
  height: 35px;
  border: none;
  margin-right: 10px;
  font-size: 20px;
  padding: 5px;
  width: 100px;
  cursor: pointer;
  font-weight: 550 !important;
}

.footer button:hover {
  background-color: rgb(10, 10, 128);
  cursor: pointer;
}
