.container {
  /* height: 100vh; */
  background: linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  padding: 50px;
  /* text-align: center; */
  color: aliceblue;
}

.container h1 {
  margin: 0px;
  font-size: 56px;
  text-decoration: underline;
}

.container button {
  margin-top: 50px;
  font-size: 25px;
  padding: 15px;
  border: none;
  border-radius: 20px;
  background-color: #f37335;
  color: aliceblue;
  font-weight: 600;
  cursor: pointer;
}

.container button:hover {
  background-color: #ed213a;
}

.inputDiv {
  /* height: 40px; */
  margin-right: 20px;
  font-size: 25px;
  border-radius: 10px;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 15px;
    flex-direction: column;
    /* border: 5px solid blue; */
    box-sizing: border-box;
  }

  .container h1 {
    font-size: 30px;
    text-align: center;
  }

  /* .container button {
    margin-top: 10px;
    font-size: 20px;
    padding: 5px;
  } */

  #AllTime {
    /* border: 5px solid brown; */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px 0px 20px;
    background-color: rgb(222, 226, 157);
    border-radius: 15px;
    box-shadow: 0px 4px 2px rgb(167, 35, 35);
  }

  #AllTime button {
    margin-top: 0px;
    font-size: 20px;
    padding: 5px;
    box-shadow: 0px 4px 2px rgb(7, 7, 139);
  }

  .otherData {
    /* border: 5px solid brown; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: rgb(222, 226, 157);
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 2px rgb(167, 35, 35);
    box-sizing: border-box;
  }

  .otherData button {
    margin-top: 0px;
    font-size: 20px;
    padding: 5px;
    box-shadow: 0px 4px 2px rgb(7, 7, 139);
  }

  .inputDiv {
    margin-right: 0px;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
  }
}
