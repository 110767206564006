.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  box-sizing: border-box;
}

.form {
  /* border: 2px solid green; */
  /* width: 800px; */
  padding: 20px;
  /* background-color: rgb(245, 245, 245); */
  background: linear-gradient(45deg, #4a569d, #dc2424);
  border-radius: 15px;
  /* box-shadow: 5px 5px 2px green; */
  /* transition: 0.5s; */
  cursor: pointer;
  color: aliceblue;
}

.toggleBtn {
  background: linear-gradient(45deg, #4a569d, #dc2424);
  border-radius: 15px;
  margin-bottom: 10px;
  width: 300px;
  color: aliceblue;
  font-size: 18px;
  padding: 6px;
}

.formElement {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 20px;
  position: relative;
}
.formElement input {
  font-size: 18px;
  padding: 5px;
  border: 0.5px solid grey;
  border-radius: 10px;
}

.formElement input:focus {
  border: none;
  box-shadow: 4px 4px 2px skyblue;
  outline: none;
  border-radius: 10px;
}

.btn {
  display: flex;
  flex-direction: row-reverse;
}

.btn input {
  border: none;
  font-size: 18px;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(40, 98, 224);
  color: aliceblue;
  cursor: pointer;
}

.btn input:hover {
  background-color: rgb(5, 50, 146);
}

.hide {
  position: absolute;
  left: 95%;
  z-index: 1000;
  color: black;
  top: 55%;
  transform: translate(-55%);
  color: grey;
}
