.container {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  border: 2px solid brown;
  padding: 20px;
  background: linear-gradient(45deg, #bdc3c7, #2c3e50);
  border-radius: 25px;
}

.container h1 {
  text-align: center;
  margin: 0px;
  margin-bottom: 30px;
  color: rgb(250, 249, 248);
  font-weight: 550;
}

.table {
  /* border: 2px solid green; */
  width: 100%;
  text-align: center;
  padding: 10px;
}

.table th,
td {
  border: 2px solid rgb(231, 231, 238);
  font-size: 22px;
}

tr {
  height: 55px;
  font-weight: 600;
}

#remove {
  border: none;
  margin-right: 10px;
  font-size: 20px;
  padding: 5px;
  border-radius: 8px;
  width: 100px;
  cursor: pointer;
  font-weight: 550 !important;
}

#remove {
  background-color: rgb(29, 243, 172);
}
#remove:hover {
  background-color: rgb(7, 139, 128);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .container h1 {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .table {
    /* border: 2px solid green; */
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 0px;
  }

  .table th,
  td {
    border: 2px solid rgb(231, 231, 238);
    font-size: 18px;
  }

  tr {
    height: auto;
    font-weight: 600;
  }

  #remove {
    border: none;
    margin: 5px;
    font-size: 18px;
    padding: 5px;
    border-radius: 8px;
    width: 80px;
    cursor: pointer;
    font-weight: 550 !important;
  }
}
