.container {
  /* border: 2px solid brown; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  box-sizing: border-box;
  background: linear-gradient(45deg, #8e9eab, #eef2f3);
  /* height: 100vh; */
  padding: 20px 50px 50px;
  width: 100%;
}

.container h1 {
  text-align: center;
  color: rgb(40, 129, 212);
  color: aliceblue;
  font-size: 40px;
  background: linear-gradient(45deg, #134e5e, #71b280);

  border-radius: 20px;
  padding: 5px;
}

.container h2 {
  text-align: center;
  color: brown;
  font-size: 30px;
  text-align: left;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px;
    /* border: 5px solid blue; */
    box-sizing: border-box;
  }

  .container h2 {
    text-align: center;
    color: brown;
    font-size: 30px;
    text-align: center;
    text-decoration: underline;
  }
}
