.container {
  /* border: 5px solid brown; */
  padding: 50px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  box-sizing: border-box;
  background-color: rgb(185, 185, 185);
}

/* add plan form */
.addPlanContainer {
  border: 2px solid rgb(42, 165, 48);
  background: linear-gradient(45deg, rgb(95, 167, 67) 50%, rgb(199, 226, 47));
  color: aliceblue;
  border-radius: 25px;
  font-weight: 500;
}
.addPlanContainer h1 {
  margin-bottom: 0px;
  text-align: center;
}

.form {
  padding: 20px;
  font-size: 22px;
}
.formElement {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.formElement input {
  border: 1px solid grey;
  padding: 10px;
  font-size: 18px;
  border-radius: 15px;
  margin: 10px 0px 10px;
}

.formElement select {
  border: 1px solid grey;
  padding: 10px;
  font-size: 18px;
  border-radius: 15px;
  margin: 10px 0px 10px;
}

.formElement input:focus {
  border: none;
  box-shadow: 4px 4px 2px rgb(207, 133, 21);
  outline: none;
  border-radius: 10px;
}

#color1,
#color2 {
  padding: 0px;
  border-radius: 0px;
}

.formbtn input {
  border: none;
  font-size: 18px;
  font-weight: 600 !important;
  padding: 10px;
  margin-right: 25px;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  color: aliceblue;
}

#clearbtn {
  background-color: rgb(165, 18, 133);
}

#clearbtn:hover {
  background-color: rgb(107, 32, 91);
}

#submitbtn {
  background-color: rgb(25, 122, 25);
}

#submitbtn:hover {
  background-color: rgb(28, 82, 28);
}

.range {
  display: flex;
}

#range1:focus {
  box-shadow: none;
}

#range2:focus {
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px;
  }
}
