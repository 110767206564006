.container {
  background-color: rgb(214, 214, 214);
  /* background: linear-gradient(45deg, rgb(83, 112, 124), rgb(202, 196, 113)); */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  position: relative;
}

.header {
  text-align: center;

  padding: 20px;
}
.header h1 {
  margin: 0;
}
.header p {
  margin: 0;
  font-size: 18px;
}
